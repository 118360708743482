import React, { useState, useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as reduxInternal from '../../redux';
import { ToggleMenuContextProvider } from './toggleMenuContext';
import FullPageLoader from '../../components/Loaders/FullPageLoader';
import Header from '../Header';
import './style.css';

const classNames = require('classnames');

const Layout = ({ children, showMenuTitleRedux, dispatch }) => {
  const { height } = useWindowDimensions();
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(false);
  const [showMenuTitle, setShowMenuTitle] = useState(showMenuTitleRedux);
  const [showHeaderComponent, setShowHeaderComponent] = useState(true);
  const toggleMenu = (e) => {
    e.preventDefault();
    setShowHeader(!showHeader);
  };

  const checkHeaderToShow = () => {
    if (
      window.location.pathname === '/patient/consultation/consultation-room' ||
      window.location.pathname === '/network-error'
    ) {
      setShowHeaderComponent(false);
    } else {
      setShowHeaderComponent(true);
    }
  };

  useEffect(() => {
    checkHeaderToShow();
  }, [window.location.pathname, location]);

  useEffect(() => {
    dispatch(reduxInternal.menu.actions.setShowMenuTitle(showMenuTitle));
  }, [showMenuTitle]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setShowHeader(true);
      checkHeaderToShow();
    }
  }, []);

  return (
    <div className="w-full flex" style={{ height }}>
      {showHeaderComponent ? (
        <Header
          toggleMenu={toggleMenu}
          showHeader={showHeader}
          showMenuTitle={showMenuTitle}
          setShowMenuTitle={setShowMenuTitle}
        />
      ) : null}
      <Suspense fallback={<FullPageLoader />}>
        <div
          className={classNames(
            'h-screen overflow-auto w-full z-0 smooth-transition',
            {
              'content-con': showHeaderComponent && showMenuTitle,
              'content-con-min': showHeaderComponent && !showMenuTitle,
            }
          )}
        >
          <ToggleMenuContextProvider
            value={{
              toggleMenu,
              checkHeaderToShow,
            }}
          >
            {children}
          </ToggleMenuContextProvider>
        </div>
      </Suspense>
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.element,
  showMenuTitleRedux: PropTypes.bool,
};

Layout.defaultProps = {
  children: undefined,
  showMenuTitleRedux: true,
};

const mapStateToProps = (state) => ({
  showMenuTitleRedux:
    reduxInternal.menu.selectors.makeSelectShowMenuTitle()(state),
});

export default connect(mapStateToProps)(Layout);
