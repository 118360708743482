const routes = {
  home: '/',
  patientProfile: '/patient/profile',
  patientAppointment: '/patient/appointments',
  patientAppointmentDetail: '/patient/appointment-details',
  consultationHistoryRating: '/consultation/rating',
  consultationHistory: '/consultation/history',
  consultationHistoryDetail: '/consultation/history-details',
  networkError: '/network-error',
  patientAppointmentWaitingRoom: '/patient/appointment/waiting-room/:id',
  patientConsultation: '/patient/consultation/*',
  checkout: '/checkout',
  checkoutComplete: '/checkout-complete',
  patientDetails: '/patient/details',
  patientAppointmentConfirmation: '/patient/appointment-confirmation',
};
export default routes;
