import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const ConsultationHistoryDetail = React.lazy(() =>
  import('../../views/ConsultationHistoryDetail')
);

const params = [];

const ConsultationHistoryDetailRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <ConsultationHistoryDetail />
      </Layout>
    </RouteParameter>
  );
};

ConsultationHistoryDetailRoute.propTypes = {};

ConsultationHistoryDetailRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ConsultationHistoryDetailRoute);
