import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';

const PatientAppointmentConfirmation = React.lazy(() =>
  import('../../views/PatientAppointmentConfirmation')
);

const params = [];

const patientAppointmentConfirmationRoute = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout>
        <PatientAppointmentConfirmation />
      </Layout>
    </RouteParameter>
  );
};

patientAppointmentConfirmationRoute.propTypes = {};

patientAppointmentConfirmationRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(patientAppointmentConfirmationRoute);
