import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const PatientAppointmentDetail = React.lazy(() =>
  import('../../views/PatientAppointmentDetail')
);

const params = [];

const PatientAppointmentDetailRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <PatientAppointmentDetail />
      </Layout>
    </RouteParameter>
  );
};

PatientAppointmentDetailRoute.propTypes = {};

PatientAppointmentDetailRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PatientAppointmentDetailRoute);
