import redux from '@doctoroncallcom/redux';
import { createSelector } from 'reselect';

const service = 'checkout';
const rootReducerKey = 'checkout';
const initialState = {
  checkoutDetails: {
    orderId: null,
    price: null,
    discountedPrice: null,
    totalPrice: null,
    transactionId: null,
    couponId: null,
    couponType: null,
    couponCode: null,
    flowType: null,
    doctorId: null,
    hospitalId: null,
    fdSpecificationId: null,
    fdSpecificationKey: null,
    consultationMode: null,
    planId: null,
    planName: null,
  },
};

const constants = {
  SET_CHECKOUT_DATA: redux.helpers.createConstant(
    service,
    'SET_CHECKOUT_DATA',
    process.env.REACT_APP_APP_NAME
  ),
  RESET_CHECKOUT_DATA: redux.helpers.createConstant(
    service,
    'RESET_CHECKOUT_DATA',
    process.env.REACT_APP_APP_NAME
  ),
  RESET_COUPON_DATA: redux.helpers.createConstant(
    service,
    'RESET_COUPON_DATA',
    process.env.REACT_APP_APP_NAME
  ),
};
const actions = {
  setCheckoutData: (data) =>
    redux.helpers.createAction(constants.SET_CHECKOUT_DATA, { data }),
  resetCheckoutData: () =>
    redux.helpers.createAction(constants.RESET_CHECKOUT_DATA),
  resetCouponData: () =>
    redux.helpers.createAction(constants.RESET_COUPON_DATA),
};
const reducer = redux.helpers.createReducer(
  {
    [constants.SET_CHECKOUT_DATA]: (draft, action) => ({
      checkoutDetails: {
        ...draft.checkoutDetails,
        ...(action.payload.data?.orderId
          ? { orderId: action.payload.data.orderId }
          : {}),
        ...(action.payload.data?.price
          ? { price: action.payload.data.price }
          : {}),
        ...(action.payload.data?.discountedPrice
          ? { discountedPrice: action.payload.data.discountedPrice }
          : {}),
        ...(action.payload.data?.totalPrice
          ? { totalPrice: action.payload.data.totalPrice }
          : {}),
        ...(action.payload.data?.transactionId
          ? { transactionId: action.payload.data.transactionId }
          : {}),
        ...(action.payload.data?.couponId
          ? { couponId: action.payload.data.couponId }
          : {}),
        ...(action.payload.data?.couponType
          ? { couponType: action.payload.data.couponType }
          : {}),
        ...(action.payload.data?.couponCode
          ? { couponCode: action.payload.data.couponCode }
          : {}),
        ...(action.payload.data?.flowType
          ? { flowType: action.payload.data.flowType }
          : {}),
        ...(action.payload.data?.doctorId
          ? { doctorId: action.payload.data.doctorId }
          : {}),
        ...(action.payload.data?.hospitalId
          ? { hospitalId: action.payload.data.hospitalId }
          : {}),
        ...(action.payload.data?.fdSpecificationId
          ? { fdSpecificationId: action.payload.data.fdSpecificationId }
          : {}),
        ...(action.payload.data?.fdSpecificationKey
          ? { fdSpecificationKey: action.payload.data.fdSpecificationKey }
          : {}),
        ...(action.payload.data?.consultationMode
          ? { consultationMode: action.payload.data.consultationMode }
          : {}),
        ...(action.payload.data?.planId
          ? { planId: action.payload.data.planId }
          : {}),
        ...(action.payload.data?.planName
          ? { planName: action.payload.data.planName }
          : {}),
      },
    }),
    [constants.RESET_COUPON_DATA]: (draft) => ({
      checkoutDetails: {
        ...draft.checkoutDetails,
        discountedPrice: initialState.checkoutDetails.discountedPrice,
        couponId: initialState.checkoutDetails.couponId,
        couponType: initialState.checkoutDetails.couponType,
      },
    }),
    [constants.RESET_CHECKOUT_DATA]: () => ({
      checkoutDetails: { ...initialState.checkoutDetails },
    }),
  },
  initialState
);

const selectLocation = (state) => state[rootReducerKey];
const selectors = {
  makeSelectCheckoutDetails: () =>
    createSelector([selectLocation], (state) => state.checkoutDetails),
};

export { actions, reducer, selectors, rootReducerKey, constants };
