import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButton from '@doctoroncallcom/dermis-web/dist/esm/MenuButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import URL from '../../utils/url';
import { SSO_LOGOUT_URL } from '../../config/constants';
import './style.css';

const classNames = require('classnames');

const Header = ({
  toggleMenu,
  showHeader,
  showMenuTitle,
  setShowMenuTitle,
}) => {
  const navigate = useNavigate();

  const navigateToLink = (url) => {
    navigate(url);
  };

  const openLink = (link) => {
    window.location.href = link;
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = new URL(SSO_LOGOUT_URL).getUrl();
  };

  return (
    <div
      className={classNames('h-screen fixed menu_con smooth-transition', {
        'menu-backdrop-show': showHeader,
        'menu-backdrop-hide': !showHeader,
      })}
    >
      <div className="backdrop" onClick={toggleMenu}>
        &nbsp;
      </div>
      <div
        className={classNames(
          'w-full h-screen fixed z-10 smooth-transition',
          {
            'header-show': showHeader,
            'header-hide': !showHeader,
          },
          { 'header-wr': !showMenuTitle, 'header-lw': showMenuTitle }
        )}
      >
        <div className="flex items-center	justify-center pt-5 pb-3 smooth-transition h-24">
          <img
            src="/assets/icons/DOC-LOGO.svg"
            alt="DoC Logo"
            // width="100px"
            className={`smooth-transition ${classNames({
              'w-24': showMenuTitle,
              'w-20': !showMenuTitle,
            })}`}
            onClick={() => navigateToLink('/')}
          />
        </div>
        <div className=" py-6 menu_con z-10 smooth-transition">
          <nav className="smooth-transition">
            <MenuButton
              label={showMenuTitle ? 'Dashboard' : ''}
              type="button"
              normal={window.location.pathname !== '/'}
              href="/"
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/')}
              extraClasses={`smooth-transition overflow-hidden ${
                !showMenuTitle ? 'flex justify-center' : ''
              }`}
            />
            <MenuButton
              label={showMenuTitle ? 'Consultation History' : ''}
              type="button"
              normal={
                !window.location.pathname.includes('/consultation/history') &&
                !window.location.pathname.includes('/rating')
              }
              href="/patient/consultation-history"
              width="100%"
              height="56px"
              icon="consultation-history"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/consultation/history')}
              extraClasses={`smooth-transition overflow-hidden ${
                !showMenuTitle ? 'flex justify-center' : ''
              }`}
            />
            <MenuButton
              label={showMenuTitle ? 'Appointments' : ''}
              type="button"
              normal={
                !window.location.pathname.includes('/patient/appointment')
              }
              href="/patient/appointments"
              width="100%"
              height="56px"
              icon="customers"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/patient/appointments')}
              extraClasses={`smooth-transition overflow-hidden ${
                !showMenuTitle ? 'flex justify-center' : ''
              }`}
            />
            <MenuButton
              label={showMenuTitle ? 'My Profile' : ''}
              type="button"
              normal={window.location.pathname !== '/patient/profile'}
              href="/patient/profile"
              width="100%"
              height="56px"
              icon="profile"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/patient/profile')}
              extraClasses={`smooth-transition overflow-hidden ${
                !showMenuTitle ? 'flex justify-center' : ''
              }`}
            />
            <MenuButton
              label={showMenuTitle ? 'My Orders' : ''}
              type="button"
              normal={true}
              href="/patient/profile"
              width="100%"
              height="56px"
              icon="cart"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() =>
                openLink(
                  'https://www.doctoroncall.com.my/medicine/account/#reorder'
                )
              }
              extraClasses={`smooth-transition overflow-hidden ${
                !showMenuTitle ? 'flex justify-center' : ''
              }`}
            />
            <MenuButton
              label={showMenuTitle ? 'Log Out' : ''}
              type="button"
              normal={true}
              href="#"
              width="100%"
              height="56px"
              icon="log-out"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={handleLogout}
              extraClasses={`smooth-transition overflow-hidden ${
                !showMenuTitle && 'flex justify-center'
              }`}
            />
          </nav>
        </div>
        {window.innerWidth > 768 ? (
          <button
            type="button"
            className="absolute bottom-0 bg-theme-minimize h-12 w-full flex flex-row items-center justify-center gap-3 px-5 py-3"
            onClick={() => setShowMenuTitle(!showMenuTitle)}
          >
            <div className="">
              <img
                src="/assets/icons/menu-icons/minimize-icon.svg"
                alt="minimize"
                className={`smooth-transition ${
                  showMenuTitle ? 'no-rotate' : 'rotate-around'
                }`}
                // width="9%"
              />
            </div>
            {showMenuTitle && (
              <div className="text-theme-icon-color transform -translate-y-1">
                Minimize
              </div>
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

Header.propTypes = {
  toggleMenu: PropTypes.func,
  showHeader: PropTypes.bool,
  showMenuTitle: PropTypes.bool,
  setShowMenuTitle: PropTypes.func,
};

Header.defaultProps = {
  toggleMenu: undefined,
  showHeader: false,
  showMenuTitle: false,
  setShowMenuTitle: () => {},
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Header);
