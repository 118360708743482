import redux from '@doctoroncallcom/redux';
import { createSelector } from 'reselect';

const service = 'rtm';
const rootReducerKey = 'rtm';
const initialState = {
  chatConfig: {
    config: {
      appId: '',
      accountName: '',
      channelName: '',
      rtmToken: '',
    },
  },
  msgBy: {
    msgByDoc: false,
  },
  messagesArray: [
    {
      name: '',
      message: '',
      msgBy: '',
      date: '',
      fileName: '',
      size: '',
      image: '',
    },
  ],
  setChatPeopleDetails: {
    doctorId: null,
    userId: null,
    userName: null,
    doctorName: null,
    clinicName: null,
  },
};

const constants = {
  SET_RTM_CHAT_CONFIG: redux.helpers.createConstant(
    service,
    'SET_RTM_CHAT_CONFIG',
    process.env.REACT_APP_APP_NAME
  ),
  SET_MSG_BY: redux.helpers.createConstant(
    service,
    'SET_MSG_BY',
    process.env.REACT_APP_APP_NAME
  ),
  SET_MESSAGES_ARRAY: redux.helpers.createConstant(
    service,
    'SET_MESSAGES_ARRAY',
    process.env.REACT_APP_APP_NAME
  ),
  SET_CHAT_PEOPLE_DETAILS: redux.helpers.createConstant(
    service,
    'SET_CHAT_PEOPLE_DETAILS',
    process.env.REACT_APP_APP_NAME
  ),
  RESET_ALL_RTM_DATA: redux.helpers.createConstant(
    service,
    'RESET_ALL_RTM_DATA',
    process.env.REACT_APP_APP_NAME
  ),
};
const actions = {
  setRTMChatConfig: (data) =>
    redux.helpers.createAction(constants.SET_RTM_CHAT_CONFIG, { data }),
  setRTMMsgBy: (data) =>
    redux.helpers.createAction(constants.SET_MSG_BY, { data }),
  setRTMMessagesArray: (data) =>
    redux.helpers.createAction(constants.SET_MESSAGES_ARRAY, { data }),
  setRTMPeopleDetails: (data) =>
    redux.helpers.createAction(constants.SET_CHAT_PEOPLE_DETAILS, { data }),
  resetAllRTMData: () =>
    redux.helpers.createAction(constants.RESET_ALL_RTM_DATA),
};
const reducer = redux.helpers.createReducer(
  {
    [constants.SET_RTM_CHAT_CONFIG]: (draft, action) => ({
      chatConfig: {
        ...draft.chatConfig,
        config: {
          appId: action.payload.data.appId,
          channelName: action.payload.data.channelName,
          rtmToken: action.payload.data.rtmToken,
          accountName: action.payload.data.accountName,
        },
      },
    }),
    [constants.SET_MSG_BY]: (draft, action) => ({
      msgBy: {
        ...draft.msgBy,
        msgByDoc: action.payload.data,
      },
    }),
    [constants.SET_MESSAGES_ARRAY]: (draft, action) => ({
      messagesArray: [
        ...draft.messagesArray,
        {
          name: action.payload.data?.name,
          message: action.payload.data?.message,
          msgBy: action.payload.data?.msgBy,
          date: action.payload.data?.date,
          ...(action.payload.data?.fileName
            ? { fileName: action.payload.data?.fileName }
            : {}),
          ...(action.payload.data?.size
            ? { size: action.payload.data?.size }
            : {}),
          ...(action.payload.data?.image
            ? { image: action.payload.data?.image }
            : {}),
        },
      ],
    }),
    [constants.SET_CHAT_PEOPLE_DETAILS]: (draft, action) => ({
      setChatPeopleDetails: {
        ...draft.setChatPeopleDetails,
        doctorId: action.payload.data.doctorId,
        userId: action.payload.data.userId,
        userName: action.payload.data.userName,
        doctorName: action.payload.data.doctorName,
        clinicName: action.payload.data.clinicName,
      },
    }),
    [constants.RESET_ALL_RTM_DATA]: () => ({
      chatConfig: { ...initialState.chatConfig },
      msgBy: { ...initialState.msgBy },
      messagesArray: [...initialState.messagesArray],
      setChatPeopleDetails: { ...initialState.setChatPeopleDetails },
    }),
  },
  initialState
);

const selectLocation = (state) => state[rootReducerKey];
const selectors = {
  makeSelectRTMChatConfig: () =>
    createSelector([selectLocation], (state) => state.chatConfig.config),
  makeSelectRTMMessageBy: () =>
    createSelector([selectLocation], (state) => state.msgBy.msgByDoc),
  makeSelectRTMMsgsArray: () =>
    createSelector([selectLocation], (state) => state.messagesArray),
  makeSelectRTMChatPeopleDetails: () =>
    createSelector([selectLocation], (state) => state.setChatPeopleDetails),
};

export { actions, reducer, selectors, rootReducerKey, constants };
