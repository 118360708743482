import { createStore, applyMiddleware, compose } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as redux from '@doctoroncallcom/redux';
import reducers from './reducers';
import api from '../utils/api';
import { persist, videoConsultation, rtm, checkout } from '../redux';

const persistConfig = {
  key: process.env.REACT_APP_APP_NAME,
  version: 1,
  storage,
  migrate: (state) => Promise.resolve(state),
  whitelist: [
    persist.rootReducerKey,
    videoConsultation.rootReducerKey,
    rtm.rootReducerKey,
    checkout.rootReducerKey,
  ],
};

const rootReducer = (state, action) => {
  const modifiedState = state;
  return reducers(modifiedState, action);
};

export const rootEpic = combineEpics(
  redux.user.epics.profile,
  redux.user.epics.updateProfile,
  redux.user.epics.getDoctorDetailsEpic,
  redux.user.epics.getDoctorIdEpic,
  redux.fileDomain.epics.getFileEpic,
  redux.fileDomain.epics.uploadFilesEpic,
  redux.fileDomain.epics.getUploadFilesUrlEpic,
  redux.fileDomain.epics.getMCFileEpic,
  redux.fileDomain.epics.getReferralFileEpic,
  redux.fileDomain.epics.getPrescriptionFileEpic,
  redux.consultations.epics.getConsultationsList,
  redux.consultations.epics.createConsultation,
  redux.consultations.epics.getConsultationHistoryDetails,
  redux.consultations.epics.getConsultationsHistoryList,
  redux.consultations.epics.setConsultationPreferenceEpic,
  redux.consultations.epics.getDoctorReferralNewLinkEpic,
  redux.consultations.epics.getDoctorMCNewLinkEpic,
  redux.consultations.epics.checkPatientConsultationDetailsStatusEpic,
  redux.consultations.epics.verifyTransactionAmountEpic,
  redux.appointments.epics.getAppointment,
  redux.appointments.epics.getAppointmentDetails,
  redux.ratingDomain.epics.postPatientReviewEpic,
  redux.prescriptions.epics.getPatientPrescriptionDataEpic,
  redux.prescriptions.epics.getDoctorPrescriptionNewLinkEpic,
  redux.chat.epics.postChatDataEpic,
  redux.chat.epics.getChatDataEpic,
  redux.alerts.epics.getAlertsList,
  redux.couponDomain.epics.postApplyCouponEpic,
  redux.otp.epics.otpCode,
  redux.plan.epics.getPlanForPriceEpic,
  redux.specification.epics.getSpecificationPriceEpic,
  redux.partner.epics.getPartnerData
);
const epicMiddleware = createEpicMiddleware({
  dependencies: { api },
});

export default function configureStore(initialState = {}) {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const middlewares = [epicMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  epicMiddleware.run(rootEpic);

  const persistor = persistStore(store);

  // Extensions
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.persistor = persistor;

  return { store, persistor };
}
