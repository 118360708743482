import { combineReducers } from 'redux';
import {
  user,
  fileDomain,
  alerts,
  consultations,
  appointments,
  ratingDomain,
  prescriptions,
  chat,
  couponDomain,
  otp,
  plan,
  specification,
  partner,
} from '@doctoroncallcom/redux';
import {
  session,
  persist,
  videoConsultation,
  rtm,
  checkout,
  menu,
} from '../redux/index';

const injectedReducers = {};

export default combineReducers({
  [session.rootReducerKey]: session.reducer,
  [user.rootReducerKey]: user.reducer,
  [fileDomain.rootReducerKey]: fileDomain.reducer,
  [videoConsultation.rootReducerKey]: videoConsultation.reducer,
  [consultations.rootReducerKey]: consultations.reducer,
  [appointments.rootReducerKey]: appointments.reducer,
  [persist.rootReducerKey]: persist.reducer,
  [rtm.rootReducerKey]: rtm.reducer,
  [ratingDomain.rootReducerKey]: ratingDomain.reducer,
  [prescriptions.rootReducerKey]: prescriptions.reducer,
  [chat.rootReducerKey]: chat.reducer,
  [couponDomain.rootReducerKey]: couponDomain.reducer,
  [checkout.rootReducerKey]: checkout.reducer,
  [menu.rootReducerKey]: menu.reducer,
  [alerts.rootReducerKey]: alerts.reducer,
  [otp.rootReducerKey]: otp.reducer,
  [plan.rootReducerKey]: plan.reducer,
  [specification.rootReducerKey]: specification.reducer,
  [partner.rootReducerKey]: partner.reducer,
  ...injectedReducers,
});
