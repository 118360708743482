import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../config/routes';
import PatientProfileRoute from './PatientProfile';
import PatientAppointmentRoute from './PatientAppointment';
import PatientAppointmentDetailRoute from './PatientAppointmentDetail';
import ConsultationHistoryRatingRoute from './ConsultationHistoryRating';
import ConsultationHistoryRoute from './ConsultationHistory';
import ConsultationHistoryDetail from './ConsultationHistoryDetail';
import NetworkError from './NetworkError';
import PatientDashboard from './PatientDashboard';
import Consultation from './Consultation';
import Checkout from './Checkout';
import CheckoutComplete from './CheckoutComplete';
import PatientDetails from './PatientDetails';
import Fallback from './Fallback';
import PatientAppointmentConfirmation from './PatientAppointmentConfirmation';

const Routing = () => (
  <Routes>
    <Route path={routes.home} element={<PatientDashboard />} />
    <Route path={routes.patientProfile} element={<PatientProfileRoute />} />
    <Route
      path={routes.patientAppointment}
      element={<PatientAppointmentRoute />}
    />
    <Route
      path={routes.patientAppointmentDetail}
      element={<PatientAppointmentDetailRoute />}
    />
    <Route
      path={routes.consultationHistoryRating}
      element={<ConsultationHistoryRatingRoute />}
    />
    <Route
      path={routes.consultationHistory}
      element={<ConsultationHistoryRoute />}
    />
    <Route
      path={routes.consultationHistoryDetail}
      element={<ConsultationHistoryDetail />}
    />
    <Route path={routes.patientConsultation} element={<Consultation />} />
    <Route path={routes.checkout} element={<Checkout />} />
    <Route path={routes.checkoutComplete} element={<CheckoutComplete />} />
    <Route path={routes.patientDetails} element={<PatientDetails />} />
    <Route path={routes.networkError} element={<NetworkError />} />
    <Route
      path={routes.patientAppointmentConfirmation}
      element={<PatientAppointmentConfirmation />}
    />
    <Route path="*" element={<Fallback />} />
  </Routes>
);

export default Routing;
