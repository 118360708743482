import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';

import Routing from '../../routes';
import configureStore from '../../store/configureStore';
import SSO from '../SSO';

const { store, persistor } = configureStore({});
const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <SSO>
          {window.location?.href?.includes('meet.dev.doctoroncall.com') ? (
            <Helmet>
              {/* eslint-disable */}
              {/* <!-- End Google Tag Manager --> */}
              <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PNHSZV5');`}</script>
            </Helmet>
          ) : (
            ''
          )}
          <Helmet>
            {/* This is actually Gleap script */}
            {/* <!-- Google Tag Manager --> */}
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KRTLRJ8');`}</script>
            {/* <!-- End Google Tag Manager --> */}
          </Helmet>

          {/* <!-- Google Tag Manager (noscript) --> */}
          {window.location?.href?.includes('meet.dev.doctoroncall.com') ? (
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-PNHSZV5"
                height="0"
                width="0"
                style="display:none;visibility:hidden"
              ></iframe>
            </noscript>
          ) : (
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-KRTLRJ8"
                height="0"
                width="0"
                style="display:none;visibility:hidden"
              ></iframe>
            </noscript>
          )}
          <Routing />
        </SSO>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
