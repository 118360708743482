import redux from '@doctoroncallcom/redux';
import { createSelector } from 'reselect';

const service = 'menu';
const rootReducerKey = 'menu';
const initialState = {
  menu: {
    showMenuTitle: true,
  },
};

const constants = {
  SET_SHOW_MENU_TITLE: redux.helpers.createConstant(
    service,
    'SET_SHOW_MENU_TITLE',
    process.env.REACT_APP_APP_NAME
  ),
};
const actions = {
  setShowMenuTitle: (data) =>
    redux.helpers.createAction(constants.SET_SHOW_MENU_TITLE, { data }),
};
const reducer = redux.helpers.createReducer(
  {
    [constants.SET_SHOW_MENU_TITLE]: (draft, action) => ({
      menu: {
        ...draft.menu,
        showMenuTitle: action.payload.data,
      },
    }),
  },
  initialState
);

const selectLocation = (state) => state[rootReducerKey];
const selectors = {
  makeSelectShowMenuTitle: () =>
    createSelector([selectLocation], (state) => state.menu.showMenuTitle),
};

export { actions, reducer, selectors, rootReducerKey, constants };
