import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';

const FullPageLoader = () =>
  ReactDOM.createPortal(
    <div className="fixed top-0 left-0 h-screen w-full flex items-center justify-center full_loader_con">
      <div className="inner">
        <img src="/assets/icons/DOC-LOGO.svg" alt="DoC Logo" width="140px" />
        <div className="loader">&nbsp;</div>
      </div>
    </div>,
    document.body
  );

export default FullPageLoader;
