export const ENV =
  window.location.href.includes('dev') ||
  window.location.href.includes('localhost')
    ? '.dev'
    : '';

export const REACT_APP_SSO_URL = `https://auth${ENV}.doctoroncall.com.my`;
// for prod
export const REACT_APP_VKEY = '61f004a707afe942e91afe14a8f3028c';
// for dev
// export const REACT_APP_VKEY = '963bf6c74a9665cfdb829271ff4a7591';
// for prod
export const REACT_APP_MERCHANT_ID = 'doctoroncallmy';
// for dev
// export const REACT_APP_MERCHANT_ID = 'SB_doctoroncall';
export const MARKETPLACE_CART_URL =
  ENV === '.dev'
    ? 'https://marketplace.dev.doctoroncall.com/cart'
    : 'https://www.doctoroncall.com/cart';
